import React, { useState, useEffect } from 'react';
import { IonFab, IonFabButton, IonIcon, IonButton } from '@ionic/react';
import { logoApple, logoAndroid, downloadOutline } from 'ionicons/icons';

const InstallButton = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            // Prevent the default behavior
            event.preventDefault();
            // Store the event to use it later
            setDeferredPrompt(event);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            // Show the install prompt
            deferredPrompt.prompt();

            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
            });
        }
    };

    return (
        <IonButton onClick={handleInstallClick} disabled={!deferredPrompt}>
            Install App<IonIcon icon={downloadOutline} />
        </IonButton>
    );
};

export default InstallButton;
