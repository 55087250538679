import React, { useState, useEffect, useRef } from 'react';
import {
    IonBadge, IonButton, IonIcon,
    IonModal, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonTitle, IonContent,
    IonText,
    IonRow,
    IonCol,
    useIonLoading,
    useIonToast,
    IonNote,
    IonInput,
    useIonModal,
    IonPage,
    IonCard,
    IonCardHeader,
    IonSegment,
    IonSegmentButton,
    IonCardSubtitle,
    IonCardContent,
    IonSelect,
    IonSelectOption,
    IonItemDivider,
    IonButtons,
    IonProgressBar,
    IonImg
} from '@ionic/react';
import { notificationsOutline, close, closeOutline, chatbubblesOutline, checkmarkSharp, alertSharp, checkmark, copySharp, cameraOutline } from 'ionicons/icons';

import { format, set } from 'date-fns';

import './Notification.css';

import axiosCall from '../data/axios';

import { getCaptchaToken } from "../Helper";

import AuthContext from "../UserContext";
import { useHistory } from 'react-router';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { Swiper, SwiperSlide } from 'swiper/react';

import { SwiperZoomModal } from '../components/SwiperZoomModal';

import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import Resizer from 'react-image-file-resizer';

const formatTimestamp = (timestamp: any) => {
    return format(new Date(timestamp), 'PPpp'); // PPpp gives a readable format like June 6, 2024 at 11:37 AM
};

export const userVerificationStatus = (authValues: any) => {

    let dataField = authValues.user?.data?.data;
    // Check if userData is JSON decodable
    if (dataField) {
        try {
            dataField = JSON.parse(dataField);
        } catch (error) {
            console.error('Error parsing user data:', error);
        }
    }

    const phoneVerified = authValues.user?.data?.phone_verified_at ? 1 : 0;
    const emailVerified = authValues.user?.data?.email_verified_at ? 1 : 0;
    const idSubmitted = authValues.user?.data?.id_number ? 1 : 0;
    const idVerified = authValues.user?.data?.id_verified_at ? 1 : 0;
    const tfnNo = dataField?.tfn ? 1 : 0;

    const verifyStatus = (phoneVerified + emailVerified + idSubmitted + idVerified + tfnNo) / 5;

    return {
        phoneVerified: phoneVerified,
        emailVerified: emailVerified,
        idSubmitted: idSubmitted,
        idVerified: idVerified,
        verifyStatus: verifyStatus,
        tfnNo: tfnNo
    };
}

const UserStatus = () => {
    const { authValues, fetchUser, logout } = React.useContext(AuthContext);

    const userVefication = userVerificationStatus(authValues);

    const PhotoIDModal = ({ dismiss }: { dismiss: (data?: any | null | undefined | number, role?: string) => void }) => {
        const inputRef = useRef<HTMLIonInputElement>(null);

        const [presentToast] = useIonToast();
        const [spinnerPresent, spinnerDismiss] = useIonLoading();

        const [idNumber, setIdNumber] = useState(authValues.user?.data?.id_number ? authValues.user.data.id_number : '');
        let id_pics = authValues?.user?.data?.id_pictures ? authValues.user.data.id_pictures : [];
        id_pics = id_pics.map((img: any) => {
            return { src: `${process.env.REACT_APP_BASE_URL}/images/user_data/${img}` };
        });
        const [images, setImages] = useState(id_pics);
        
        let dataField = authValues.user?.data?.data;

        // Check if userData is JSON decodable
        if (dataField) {
            try {
                dataField = JSON.parse(dataField);
            } catch (error) {
                console.error('Error parsing user data:', error);
            }
        }
        const [tfn, setTfn] = useState(dataField?.tfn ? dataField.tfn : '');

        const zoomModalDismiss = () => {
            dismissZoomModal();
        };
    
        const [clickedImageKey, setClickedImageKey] = useState(0);
    
        const [presentZoomModal, dismissZoomModal] = useIonModal(SwiperZoomModal, { zoomModalDismiss, images, clickedImageKey });
    
        function slideClick(imgKey: number) {
    
            setClickedImageKey(imgKey);
    
            presentZoomModal({
                initialBreakpoint: 1,
                breakpoints: [0, 1],
                backdropBreakpoint: 0,
                animated: true,
                cssClass: 'modal-fullscreen'
            });
    
        }

        const takePicture = async () => {

            try {
                const cameraResult = await Camera.getPhoto({
                    quality: 100,
                    //allowEditing: true,
                    width: 500,
                    resultType: CameraResultType.Uri,
                    source: CameraSource.Prompt,
                    promptLabelHeader: 'Choose Picture',
                    promptLabelCancel: 'Cancel',
                    promptLabelPhoto: 'From Photo Gallery',
                    presentationStyle: 'fullscreen',
                    webUseInput: true
                });
    
                const path = cameraResult?.webPath || cameraResult?.path;
    
                setImages((prevImages:any) => [...prevImages, { src: path }]);
    
                return true;
    
            } catch (e) {
                console.log(e);
            }
    
        };
    
        const resizeFile = (file: any) =>
            new Promise((resolve) => {
                Resizer.imageFileResizer(
                file,
                720,
                720,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

        function deleteImage(imgKey: number) {

            let updatedImages = images.filter((item:any, i:number) => imgKey !== i);
    
            //const slides = document.querySelectorAll('ion-slide');
    
            if (updatedImages.length > 0) {
            
                setImages(updatedImages);
    
            } else {
                setImages([]);
            }
            
        }

        async function save() {

            let dt = new Date();
    
            let data = {
                "idNumber": idNumber,
                "tfn": tfn,
            };
    
            if(!idNumber) {
                presentToast({
                    message: 'Photo ID number is required',
                    icon: alertSharp,
                    color: 'danger',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                
                });
                return;
            }
    
            if(images == '' || images.length < 2) {
                presentToast({
                    message: 'Images of the Photo ID are required, and a selfie of you displaying the ID.',
                    icon: alertSharp,
                    color: 'danger',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                });
                return;
            }
    
            let formData = new FormData();
    
            Object.entries(data).map(([key, value]) => {
                formData.append(key, value);
            });
    
            for (const img of images) {
                /**
                 * Fetches from both local for new images and from public folder on backend server
                 * To be able to fetch from backend server, this had to be done on .htaccess in public folder
                 * Header always set Access-Control-Allow-Origin "*"
                 * Might have to move the image storage to some other location in the backend.
                 * Also need to delete existing image from the file location.
                 */
                const response = await fetch(img.src);
                const blob = await response.blob(); 
                let imgFile = new File([blob], Math.random() +'.'+ blob?.type.split('/')[1], {'type': blob?.type});
                let resizedImg = await resizeFile(imgFile) as Blob;
                formData.append("images[]", resizedImg);
            }
    
            await getCaptchaToken('SAVE').then((token: any) => { // Here wait token generated
                if(token) {
                    formData.append('captchaToken', token);
                    formData.append('captchaAction', 'SAVE');
                }
            });
    
            spinnerPresent({
                message: 'Saving. Please wait...',
                spinner: 'bubbles',
                animated: true,
                cssClass: 'drukre-loading'
            });
    
            axiosCall("/api/saveUserIDdoc", "post", formData, true, 'multipart/form-data')
            .then((res) => {
                //setMyMarkersCheck(false);
                spinnerDismiss();
                presentToast({
                    message: 'ID Submitted. It will go through a verification process!',
                    icon: checkmark,
                    color: 'success',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                  });
            })
            .catch((error) => {
                //setMessage("Auth failure! Please create an account");
                //setIserror(true);
                spinnerDismiss();
                presentToast({
                    message: error.message,
                    icon: alertSharp,
                    color: 'danger',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                  });
            });
    
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle> Complete your account verification</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonCard className="ion-padding">
                        <IonRow color="primary">
                            <IonCol className="ion-text-center">
                                <IonText>
                                    Phone Verified: {userVefication.phoneVerified ? <IonBadge color="success">Verified</IonBadge> : <IonBadge color="danger">Not Verified</IonBadge>}
                                </IonText>
                            </IonCol>
                            <IonCol className="ion-text-center">
                                <IonText>
                                    Email Verified: {userVefication.emailVerified ? <IonBadge color="success">Verified</IonBadge> : <IonBadge color="danger">Not Verified</IonBadge>}
                                </IonText>
                            </IonCol>
                            <IonCol className="ion-text-center">
                                <IonText>
                                    ID Submitted: {userVefication.idSubmitted ? <IonBadge color="success">Submitted</IonBadge> : <IonBadge color="danger">Not Submitted</IonBadge>}
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <IonText>
                                    ID Verified: {userVefication.idVerified ? <IonBadge color="success">Verified</IonBadge> : <IonBadge color="danger">Not Verified</IonBadge>}
                                </IonText>
                            </IonCol>
                            <IonCol className="ion-text-center">
                                <IonText>
                                    TPN Provided: {userVefication.tfnNo ? <IonBadge color="success">Provided</IonBadge> : <IonBadge color="danger">Not Provided</IonBadge>}
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonCard>
                    <IonItem className="ion-padding">
                        Government Issued Photo ID(Such as Passport, ID Card, Driver's License)
                    </IonItem>
                    <IonRow className="ion-padding">
                        <IonCol size="8" offset='2' className="ion-text-center">
                            <IonInput labelPlacement="floating" value={idNumber} onIonChange={(e) => setIdNumber(e.detail.value!)} required
                                fill="outline" label="Enter your ID number here" />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="7">
                            <IonNote>Two images required. <br/>1. Clear Photo of the ID document. <br/>2. Your selfie displaying the ID.</IonNote>
                        </IonCol>
						<IonCol size="5" className="ion-text-end">
							<IonButton fill="outline" color="drukre" onClick={takePicture}>
								<IonIcon icon={cameraOutline} /> &nbsp; Upload Image
							</IonButton>
						</IonCol>
					</IonRow>
                    <IonRow>
						<IonCol>
							<Swiper slidesPerView={5} initialSlide={2} speed={400} spaceBetween={10} centeredSlides={true}
								zoom={true} key={images.map((slide: any) => slide.id).join('_')}>
								{images.map((image:any, i:number) => {
									//if(!image.src.includes('undefined')) { 
									 	return <SwiperSlide key={i}>
												<div className="swiper-zoom-container">
													<IonIcon className="img-delete" icon={closeOutline} onClick={(e) => deleteImage(i)}/>
													<IonImg src={image.src} onClick={() => slideClick(i)}/>
												</div>
											</SwiperSlide>
									//}
								}
								)}
							</Swiper>
						</IonCol>
					</IonRow>
                    <IonRow className="ion-padding">
                        <IonCol size="8" offset='2' className="ion-text-center">
                            <IonInput labelPlacement="floating" value={tfn} onIonChange={(e) => setTfn(e.detail.value!)} required
                                fill="outline" label="Enter your Tax Payer No(TPN) here" />
                        </IonCol>
                    </IonRow>
                    <IonItemDivider/>
                    <IonItem>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton color="medium" onClick={() => dismiss(null, 'cancel')}>
                                    Cancel
                                </IonButton>
                            </IonButtons>
                            <IonButtons slot="end">
                                <IonButton color="drukre" onClick={save} strong={true}>
                                    Submit
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonItem>
                </IonContent>
            </IonPage>
        );
    };

    const [presentOTP, setPresentOTP] = useIonModal(PhotoIDModal, {
        dismiss: (data: string, role: string) => setPresentOTP(data, role)
    });
    const viewAccount = () => {
        presentOTP({
            initialBreakpoint: 0.7,
            breakpoints: [0, 0.4, 0.7, 1],
            backdropBreakpoint: 0,
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'confirm') {
                }
            }
        });
    }

    return (
        <>
        User Account Verification {userVefication.verifyStatus * 100} %
        <IonProgressBar value={userVefication.verifyStatus} style={{height: "30px"}} color="tertiary" onClick={viewAccount}>
        </IonProgressBar> 
        <span onClick={viewAccount}>Click here to complete</span>
        </>
    );
};

export default UserStatus;
