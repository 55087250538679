// src/components/TermsAndConditions.js
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar, IonTitle, IonGrid } 
    from '@ionic/react';

import { chevronBackOutline,} from "ionicons/icons";

const TermsAndConditions = () => {

    let appName = 'EThrom';
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton color="dark" text="Go Back" defaultHref="/">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Go Back
                        </IonBackButton>
                    </IonButtons>
                </IonToolbar>
			</IonHeader>
            <IonContent>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton color="dark" text="Go Back" defaultHref="/">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Go Back
                        </IonBackButton>
                    </IonButtons>
                </IonToolbar>
                <div style={{ padding: '20px', lineHeight: '1.6' }}>
                    <h1>Terms, Conditions and Privacy Policies</h1>

                    <h2>1. Introduction</h2>
                    <p>
                        Welcome to {appName} (the “Site” or “App”). These Terms and Conditions (“Terms”) govern your use of our marketplace platform, accessible via our website and {appName}. By accessing or using our Site/App, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our Site/App.
                    </p>

                    <h2>2. Definitions</h2>
                    <p>
                        "User" refers to any individual or entity who accesses or uses the Site/App.
                        "Buyer" refers to a User who purchases goods or services from the Site/App.
                        "Seller" refers to a User who lists goods or services for sale on the Site/App.
                        "Marketplace" refers to the platform provided by {appName} for Buyers and Sellers to engage in transactions.
                    </p>

                    <h2>3. User Accounts</h2>
                    <p>
                        Users must create an account to access certain features of the Site/App. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                        You must be at least 18 years old to create an account.
                    </p>

                    <h2>4. Listings and Transactions</h2>
                    <p>
                        Sellers are responsible for providing accurate and complete information about their listings. Sellers will go through a verification process before their listings are published on the Site/App.
                        Buyers are responsible for reading the listing description before making a purchase.
                        Once a transaction is completed, Buyers and Sellers are responsible for fulfilling their obligations as outlined in the transaction details.
                        A 7 days grace period is given to the buyer to return the product if not satisfied. After 7 days, the product is considered sold.
                        Sellers are eligible to submit for payment after 7 days of the product being sold with no disputes from the buyer.
                        The seller also requires their identity to be verified before they can submit for payment.
                    </p>

                    <h2>5. Payments</h2>
                    <p>
                        Payments for goods or services purchased on the {appName} are processed through our third-party payment processors.
                        By making a purchase, you agree to the terms and conditions of our Payment Processors.
                        {appName} does not store or process payment information. All payment transactions are handled by our Payment Processors.
                    </p>

                    <h2>6. Fees and Commissions</h2>
                    <p>
                        {appName} may add a commission on top of the seller's pricing to cover the cost of maintaining the platform.
                    </p>

                    <h2>7. Refunds and Disputes</h2>
                    <p>
                        Refunds and returns are governed by the policies set forth by the individual Sellers. Buyers should review these policies before making a purchase.
                        In the event of a dispute between a Buyer and a Seller, {appName} may, but is not obligated to, assist in resolving the dispute.
                    </p>

                    <h2>8. Prohibited Activities</h2>
                    <p>
                        Users are prohibited from using the Site/App for any illegal or unauthorized purpose. This includes selling illegal substances, counterfeit goods, engaging in fraud, or violating any intellectual property rights.
                        Users must not violate any laws in their jurisdiction (including but not limited to copyright laws).
                        Users must not transmit any worms, viruses, or any code of a destructive nature.
                    </p>

                    <h2>9. Intellectual Property</h2>
                    <p>
                        All content on the Site/App, including but not limited to text, graphics, logos, images, and software, is the property of {appName} or its content suppliers and is protected by intellectual property laws.
                        Users may not use any content from the Site/App without the prior written permission of {appName}.
                    </p>

                    <h2>10. Limitation of Liability</h2>
                    <p>
                        {appName} is not responsible for any damages or losses resulting from the use of the Site/App or from any transactions between Users.
                        {appName} does not guarantee the accuracy, completeness, or usefulness of any information provided on the Site/App.
                    </p>

                    <h2>11. App Store EULA</h2>
                    <p>
                        If you download the {appName} app from the Apple App Store, your use of the app is also subject to Apple's Licensed Application End User License Agreement (EULA). This includes Apple's rules regarding app usage, intellectual property, privacy, and content restrictions. Please review the EULA carefully to understand your rights and responsibilities as an app user.

                        User-Generated Content
                        {appName} allows Users, including Buyers and Sellers, to generate content in the form of listings, reviews, messages, and other materials. As part of our compliance with the App Store's guidelines, we adhere to the following rules for apps with user-generated content:

                        Filtering: We have implemented a method for filtering objectionable material from being posted to the app. Listings and comments are moderated, and offensive or inappropriate content will be filtered or removed as necessary.
                        Reporting and Response: Users can report offensive content directly through the app, and {appName} will respond to such reports promptly.
                        Blocking Users: {appName} has a mechanism to block abusive users from accessing the service.
                        Contact Information: We make it easy for users to contact us regarding content issues. You can reach us at info@ethrom.org.
                        Please note that apps or services that primarily engage in or promote objectionable content—such as pornography, anonymous bullying, or physical threats—are not permitted. Content of this nature will be removed immediately, and the responsible users may be banned from the platform.

                        Additionally, any mature content (including "NSFW" material) is not allowed in listings unless explicitly permitted by the platform's guidelines. Such content must be hidden by default and only shown when the user chooses to view it.
                    </p>
                    <h2>12. Changes to Terms</h2>
                    <p>
                        {appName} reserves the right to modify these Terms at any time. Users will be notified of any changes via email or through a notice on the Site/App.
                        Continued use of the Site/App after any such changes constitutes your acceptance of the new Terms.
                    </p>

                    <h2>13. Governing Law</h2>
                    <p>
                        These Terms shall be governed by and construed in accordance with the laws of the Kingdom of Bhutan, without regard to its conflict of law principles.
                    </p>

                    <h2>14. Contact Information</h2>
                    <p>
                        For any questions or concerns regarding these Terms, please contact us.
                    </p>
                    
    <h1>Privacy Policy</h1>

    <h2>1. Information We Collect:</h2>
    <ul>
        <li>Personal Information: When you register an account or make a purchase, we may collect your name, email address, phone number, and billing information.</li>
        <li>Transaction Data: We collect details of your purchases, including order history, shipping addresses, and payment information.</li>
        <li>Device Information: We may collect information about the device you use to access our app, including IP address, browser type, and operating system.</li>
    </ul>

    <h2>2. How We Use Your Information:</h2>
    <ul>
        <li>To process your orders and provide customer support.</li>
        <li>To improve our app and services based on your usage patterns.</li>
        <li>To communicate with you about promotions, updates, and account-related matters.</li>
    </ul>

    <h2>3. Sharing Your Information:</h2>
    <ul>
        <li>We do not sell or rent your personal information to third parties.</li>
        <li>We may share your information with trusted service providers who assist us in operating our app and providing services to you.</li>
    </ul>

    <h2>4. Data Security:</h2>
    <ul>
        <li>We implement industry-standard security measures to protect your personal information from unauthorized access and misuse.</li>
    </ul>

    <h2>5. Your Choices:</h2>
    <ul>
        <li>You can update your account information and communication preferences at any time.</li>
        <li>You may opt out of promotional emails by following the unsubscribe instructions in the email.</li>
    </ul>

    <h2>6. Children's Privacy:</h2>
    <ul>
        <li>Our app is not intended for children under the age of 13. We do not knowingly collect personal information from children.</li>
    </ul>

    <h2>7. Changes to This Privacy Policy:</h2>
    <ul>
        <li>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our app.</li>
    </ul>

    <h2>8. Contact Us:</h2>
    <ul>
        <li>If you have any questions or concerns about this privacy policy or our data practices, please contact us.</li>
    </ul>

                </div>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton color="dark" text="Go Back" defaultHref="/">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Go Back
                        </IonBackButton>
                    </IonButtons>
                </IonToolbar>
            </IonContent>
        </IonPage>
    );
};

export default TermsAndConditions;
