import {
    IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon,
    IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonModal, IonNote, IonPage, IonProgressBar, IonRow,
    IonSearchbar, IonTitle, IonToolbar,
    useIonLoading,
    useIonToast, useIonModal,
    IonThumbnail,
    IonAvatar,
    IonImg,
    IonSegmentButton,
    IonSegment,
    IonCard,
    IonFooter,
    IonItemDivider,
    IonSelect,
    IonSelectOption,
    IonTextarea
} from "@ionic/react";
import { cart, cartOutline, checkmark, airplane, chevronBackOutline, personCircle, search, searchOutline, alertSharp, thumbsUp, copySharp } from "ionicons/icons";
import { useEffect, useRef, useState, useContext, useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router";

import { Clipboard } from "@capacitor/clipboard";

import { OverlayEventDetail } from '@ionic/core/components';

import AuthContext from "../UserContext";

import axiosCall from '../data/axios';

import { CategoryStore } from '../data/CategoryStore';

import UserStatus from '../components/UserStatus';

import { userVerificationStatus } from '../components/UserStatus';

import { format } from 'date-fns';
const formatTimestamp = (timestamp: any) => {
    return format(new Date(timestamp), 'PPpp'); // PPpp gives a readable format like June 6, 2024 at 11:37 AM
};

const UpdateModal = ({ selectedOrder, dismiss }: 
    { selectedOrder: any, 
        dismiss: (data?: any | null | undefined | number, role?: string) => void 
    }) => {
    const { authValues } = useContext(AuthContext);

    const inputRef = useRef<HTMLIonInputElement>(null);
    const [completionMethod, setCompletionMethod] = useState('');

    const [bankAccount, setBankAccount] = useState(authValues.user.data.bank_account);

    const [presentToast] = useIonToast();

    const handleConfirmClick = () => {
        // Check your condition here (example condition: completionMethod must be selected)
        if (selectedOrder.action === 'completion' && !(completionMethod == 'delivered' || completionMethod == 'picked')){
            presentToast({
                message: 'Please select a completion method before confirming.',
                icon: alertSharp,
                    color: 'danger',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
            });
            return;
        }

        if (selectedOrder.action === 'payment_request' && !bankAccount){
            presentToast({
                message:'Please update your bank account details in your profile before requesting payment.',
                icon: alertSharp,
                    color: 'danger',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
            });
            return;
        }

        dismiss({
            order: selectedOrder,
            bank_account_details: bankAccount,
            msg: inputRef.current?.value,
            completionMethod: completionMethod
        }, 'confirm');
    };
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Order {selectedOrder.action}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {selectedOrder.action == 'payment_request' ?
                    <div >
                        <IonNote className="ion-padding">Please confirm your bank account details below</IonNote>
                        <IonTextarea labelPlacement="floating" fill="outline" 
                            label="Bank Account Details" 
                            autoGrow={true} 
                            value={bankAccount}
                            onIonChange={e => setBankAccount(e.target.value)} >
                        </IonTextarea>
                    </div>
                :  
                <>
                    <IonItem className="ion-padding">
                        For {selectedOrder.action} of the order click the confirm button. 
                    </IonItem>
                    <IonRow>
                        <IonCol>
                            <IonInput ref={inputRef} labelPlacement="floating" fill="outline" label="Enter a message for the customer." />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {selectedOrder.action != 'rejection' && (
                                <div >
                                    <IonSelect labelPlacement="floating" fill="outline" interface="popover"
                                        onIonChange={e => setCompletionMethod(e.target.value)} label="Already Delivered/Picked?">
                                        <IonSelectOption value="null">Pending</IonSelectOption>
                                        <IonSelectOption value="delivered">Delivered</IonSelectOption>
                                        <IonSelectOption value="picked">Picked</IonSelectOption>
                                    </IonSelect>
                                <IonNote>If you are just confirming the order you need not select the above options.</IonNote>
                                </div>
                            )}
                        </IonCol>
                    </IonRow>
                </>
                }
                <IonItemDivider/>
                <IonItem>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton color="medium" onClick={() => dismiss(null, 'cancel')}>
                                Cancel
                            </IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonButton color="drukre" 
                                onClick={handleConfirmClick} strong={true}>
                                Confirm
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonItem>
            </IonContent>
        </IonPage>
    );
};

const MyOrders = () => {

    const history = useHistory();

    const { authValues } = useContext(AuthContext);

    const userVerification = userVerificationStatus(authValues);

    useLayoutEffect(() => {
        if (!authValues.authenticated) {
            history.push('/login');
        }
    }, [authValues]);

    const categories = CategoryStore.useState(s => s.categories);

    const [ordersCheck, setOrdersCheck] = useState(false);

    const [buffer, setBuffer] = useState(0.06);

    const [progress, setProgress] = useState(0);

    const [myOrders, setMyOrders] = useState<any>([]);

    const fetchOrders = async (filter: any) => {

        setProgress(0);
        setBuffer(0.06);

        // Set progress to 25% when fetching starts
        setProgress(0.25);

        await axiosCall("/api/orders", 'get', filter, true)
            .then((res) => {

                let orders = res.data.data;

                setMyOrders(orders);

                setOrdersCheck(true);

                // Increment progress by 25% every second after fetching is complete
                const interval = setInterval(() => {
                    setProgress((prevProgress) => prevProgress >= 1 ? 1 : prevProgress + 0.25);
                    setBuffer((prevBuffer) => prevBuffer >= 1 ? 1 : prevBuffer + 0.25);
                }, 1000);

                // Clear interval when progress reaches 100%
                if (progress >= 1) {
                    clearInterval(interval);
                }

            })
            .catch((error) => {
                //setIserror(true);
                setOrdersCheck(true);
            });
    }

    const [filterQuery, setFilterQuery] = useState({});

    useEffect(() => {

        var q = {};

        if (filterQuery)
            q = Object.assign(q, filterQuery);

        fetchOrders(q);

        if (`${process.env.REACT_APP_ENVIRONMENT}` == 'development') {

        } else {
            //setMarkers(initialMarkers);
            //setProductsCheck(true);
        }

    }, [filterQuery]);

    const [selectedOrder, setSelectedOrder] = useState({});

    const [presentingElement, setPresentingElement] = useIonModal(UpdateModal, {
        selectedOrder, dismiss: (data: string, role: string) => setPresentingElement(data, role)
    });

    const orderAction = (orderId: any, action: any) => {
        setSelectedOrder({ id: orderId, action: action });
        presentingElement({
            initialBreakpoint: 0.5,
            breakpoints: [0, 0.4, 0.7, 1],
            backdropBreakpoint: 0,
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'confirm') {

                    afterSave(ev.detail.data);

                }
            }
        });
    };

    const [spinnerPresent, spinnerDismiss] = useIonLoading();
    const [presentToast] = useIonToast();

    function afterSave(data: any) {

        setPresentingElement();

        spinnerPresent({
            message: 'Saving. Please wait...',
            spinner: 'bubbles',
            animated: true,
            cssClass: 'drukre-loading'
        });

        let api = "/api/orders/action";
        let method = 'post';
        if (data.order.action == 'payment_request') {
            api = "/api/update-payment-request/" + data.order.id;
            method = 'patch';
        }

        axiosCall(api, method, data, true)
            .then((res) => {
                //setMyMarkersCheck(false);
                spinnerDismiss();
                presentToast({
                    message: 'Order updated!',
                    icon: checkmark,
                    color: 'success',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                });
            })
            .catch((error) => {
                //setMessage("Auth failure! Please create an account");
                //setIserror(true);
                spinnerDismiss();
                presentToast({
                    message: error.message,
                    icon: alertSharp,
                    color: 'danger',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                });
            });
    }

    const categoryFilter = (catId: any) => {
        let cat = categories.filter((f: any) => catId == f.id);
        return cat;
    };
    
    const copyToClipboard = async (text: string): Promise<void> => {
        try {
            await Clipboard.write({
                string: text
            });
            presentToast({
                message: 'Phone number copied to clipboard.',
                duration: 3000,
                color: 'success'
            });
        } catch (err) {
            console.error('Failed to copy text:', err);
            presentToast({
                message: 'Failed to copy text to clipboard.',
                duration: 3000,
                color: 'danger'
            });
        }
    };
    
    return (

        <IonPage id="orders-page">
            <IonHeader>
                <IonToolbar>

                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding ion-text-center">
                <IonToolbar>
                    <IonSegment onIonChange={e => setFilterQuery({ status: e.detail.value })}>
                        <IonSegmentButton value="pending">
                            <IonLabel>Open Orders</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="confirmed">
                            <IonLabel>Confirmed Orders</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="completed">
                            <IonLabel>Closed Orders</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="rejected">
                            <IonLabel>Rejected Orders</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>

                <IonGrid>
                    <IonRow>
                        <IonCol size-md="10" offset-md="1">
                            <IonRow>
                                <IonCol>
                                    <IonProgressBar color='drukre' buffer={buffer} value={progress}></IonProgressBar>
                                </IonCol>
                            </IonRow>
                            <IonRow class="ion-justify-content-center ion-hide-md-down">
                                <IonCol size="2">

                                </IonCol>
                                <IonCol sizeXs="12" sizeSm="6" sizeMd="6">
                                    Order Details
                                </IonCol>
                                <IonCol size="4">
                                    Purchaser Details
                                </IonCol>
                            </IonRow>
                            {myOrders.map((ord: any, i: any) => {
                                let purchaser_details = JSON.parse(ord.purchaser_details);
                                let delivery_location = JSON.parse(ord.delivery_location);
                                return (
                                    <IonCard key={i}>
                                        <IonRow>
                                            <IonCol style={{ display: "flex", justifyContent: "center", alignItems: "center" }} sizeMd="2">
                                                <IonAvatar>
                                                    <IonImg src={`${process.env.REACT_APP_PRODUCT_ASSETS}/${JSON.parse(ord.images)[0]}`} />
                                                </IonAvatar>
                                            </IonCol>
                                            <IonCol class="ion-text-left" sizeXs="12" sizeSm="6" sizeMd="6">
                                                Name: {ord.name} <br />
                                                Price: Nu. <IonBadge color="secondary">{ord.price} </IonBadge><br />
                                                Category: {ord.category_description} <br />
                                                Quantity: {ord.quantity} <br />
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeSm="4" sizeMd="4">
                                                Name: {purchaser_details.userData.name} <br />
                                                Email: {purchaser_details.userData.email} <br />
                                                Phone: {purchaser_details.userData.phone} 
                                                <IonButton onClick={() => copyToClipboard(purchaser_details.userData.phone)}> <IonIcon icon={copySharp} />&nbsp;Copy</IonButton><br />
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol sizeXs="6" sizeSm="6" sizeMd="6">
                                                Sub Order #: {ord.sub_order_number} <br />
                                                Total: Nu. {ord.unit_price} <br />
                                                Status: <IonBadge color="secondary">{ord.status} </IonBadge><br />
                                                Location: {delivery_location.address.price ? delivery_location.address.dzo : ord.location_name} <br />
                                                {delivery_location.address.price ? (
                                                    <>
                                                        Address: {delivery_location.address.address} 
                                                        <br /> Delivery Charge: Nu. <IonBadge color="secondary"> {delivery_location.address.price}</IonBadge>
                                                        <br />
                                                    </>
                                                ) : ''}
                                                Note from Buyer: {ord.note} <br />
                                            </IonCol>
                                            <IonCol sizeXs="6" sizeSm="6" sizeMd="6">
                                                Payment #: {ord.order.payment_number} <br />
                                                Order Number: {ord.order.order_number} <br />
                                                Payment Method: {ord.order.payment_method} <br />
                                                Payment Status: <IonBadge color={ord.order.payment_status == 'unsuccessful' ? 'danger' : 'success'}> {ord.order.payment_status} </IonBadge><br />
                                            </IonCol>
                                        </IonRow>
                                        <p style={{ fontSize: "small" }}>{formatTimestamp(ord.created_at)}</p>
                                        {ord.status == 'pending' && (
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton color="secondary" onClick={() => orderAction(ord.id, 'confirmation')}>
                                                        <IonIcon icon={thumbsUp} slot="start" />
                                                        Confirm &nbsp;<span className="ion-hide-sm-down">Order</span>
                                                    </IonButton>
                                                    <IonButton color="danger" onClick={() => orderAction(ord.id, 'rejection')}>
                                                        <IonIcon icon={alertSharp} slot="start" />
                                                        Reject &nbsp;<span className="ion-hide-sm-down"> Order</span>
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        )}
                                        {ord.status == 'confirmed' && (
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton color="drukre" onClick={() => orderAction(ord.id, 'completion')}>
                                                        <IonIcon icon={checkmark} slot="start" />
                                                        Complete &nbsp;<span className="ion-hide-sm-down">Order</span>
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        )}
                                        {ord.status == 'completed' && (
                                            <IonRow>
                                                <IonCol>
                                                    {ord.payment_done_at ? (
                                                        <IonBadge color="success">Payment done on {formatTimestamp(ord.payment_done_at)}</IonBadge>
                                                    ) : 
                                                    ord.payment_requested_at ? (
                                                        <IonBadge color="warning">Payment under process</IonBadge>
                                                    ) : 
                                                    userVerification.verifyStatus === 1 && new Date(ord.completion_date) < new Date() && 
                                                    ((new Date().getTime() - new Date(ord.completion_date).getTime()) / (1000 * 60 * 60 * 24)) > 7 ? (
                                                        <IonButton color="drukre" onClick={() => orderAction(ord.id, 'payment_request')}>
                                                            <IonIcon icon={checkmark} slot="start" />
                                                            Request For Payment 
                                                        </IonButton>
                                                    ) : 
                                                        <IonBadge color="warning">Not Payment Ready Yet</IonBadge>
                                                    }
                                                </IonCol>
                                            </IonRow>
                                        )}
                                    </IonCard>
                                )
                            }
                            )}
                        </IonCol>
                    </IonRow>
                    <IonRow>
						<IonCol size="8" offset="2" className="ion-text-center">
							<UserStatus />
						</IonCol>
					</IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default MyOrders;