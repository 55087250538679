import {
    IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon,
    IonInfiniteScroll, IonInfiniteScrollContent, IonNote, IonPage, IonProgressBar, IonRow,
    IonSearchbar, IonTitle, IonToolbar, IonLabel, IonRange, IonCardSubtitle,
    IonInput,
    IonText
} from "@ionic/react";
import { cart, cartOutline, chevronBackOutline, closeOutline, search, searchOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import ProductCard from "../components/ProductCard";

import { CartStore } from "../data/CartStore";
import { ProductStore } from "../data/ProductStore";

import styles from "./CategoryProducts.module.css";
import { Preferences } from "@capacitor/preferences";

interface RecentSearch {
    name: string;
    place: string;
    min_price: number;
    max_price: number;
}

const SearchProducts = () => {

    const history = useHistory();

    const params = history.location.state as { data: string, location?: string, priceMin?: number, priceMax?: number };

    const [query, setQuery] = useState<any | null>(params?.data);

    const cartRef = useRef<HTMLIonIconElement>(null);

    const products = ProductStore.useState(s => s.products);
    const shopCart = CartStore.useState(s => s.product_ids);
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [amountLoaded, setAmountLoaded] = useState(6);

    const [priceCeiling, setPriceCeiling] = useState(0);
    const [priceFloor, setPriceFloor] = useState(0);
    const [priceMin, setPriceMin] = useState(params?.priceMin || 0);
    const [priceMax, setPriceMax] = useState(params?.priceMax || 0);
    function priceKnobChange(event: any) {
        setPriceMin(event.detail.value.lower);
        setPriceMax(event.detail.value.upper);
    }
    const [location, setLocation] = useState<any | null>(params?.location || '');

    //RECENT SEARCHES
    const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);
    const [showRecentSearches, setShowRecentSearches] = useState(false);
    
    useEffect(() => {
        const loadRecentSearches = async () => {
          const { value } = await Preferences.get({ key: 'recentSearches' });
          if (value) {
            setRecentSearches(JSON.parse(value));
          }
        };
    
        loadRecentSearches();
      }, []);

    // Update the history state whenever the state variables change
    useEffect(() => {
        history.replace({
            pathname: history.location.pathname,
            state: { ...params, location, priceMin, priceMax },
        });
    }, [location, priceMin, priceMax, history, params]);

    const clearFilters = () => {
        setLocation('');
        setPriceMin(0);
        setPriceMax(0);
    }

    const [buffer, setBuffer] = useState(0.06);
    const [progress, setProgress] = useState(0);

    const filter = (data: any) => {

        setProgress(0);
        setBuffer(0.06);

        // Set progress to 25% when fetching starts
        setProgress(0.25);

        setSearchResults([]);
        data = data?.toLowerCase();
        const tempResults = products.filter((category: any) => {
            category.products.filter((product: any) => {
                if (product.description.toLowerCase().indexOf(data) > -1 || product.name.toLowerCase().indexOf(data) > -1) {
                    let res = { product: product, category: category };
                    if (product.price > priceCeiling)
                        setPriceCeiling(parseFloat(product.price) + 1000);
                    if (product.price < priceFloor)
                        setPriceFloor(parseFloat(product.price) - 1000);

                    if (location !== '' && location !== null) {
                        let isLocationFound = false;
                        if (Array.isArray(product.delivery_locations) && product.delivery_locations.length > 0) {
                            product.delivery_locations.filter((loc: any) => {
                                if (loc.location !== '' && loc.location !== null && loc.location.toLowerCase().indexOf(location.toLowerCase()) > -1) {
                                    isLocationFound = true;
                                }
                            });
                        }
                        if (isLocationFound) {
                            if (priceMax !== 0) {
                                if (product.price >= priceMin && product.price <= priceMax)
                                    setSearchResults(prev => [...prev, res]);
                            } else
                                setSearchResults(prev => [...prev, res]);
                        }
                    }
                    else {
                        if (priceMax !== 0) {
                            if (product.price >= priceMin && product.price <= priceMax)
                                setSearchResults(prev => [...prev, res]);
                        } else
                            setSearchResults(prev => [...prev, res]);
                    }
                }
            });

            // Increment progress by 25% every second after fetching is complete
            const interval = setInterval(() => {
                setProgress((prevProgress) => prevProgress >= 1 ? 1 : prevProgress + 0.25);
                setBuffer((prevBuffer) => prevBuffer >= 1 ? 1 : prevBuffer + 0.25);
            }, 1000);

            // Clear interval when progress reaches 100%
            if (progress >= 1) {
                clearInterval(interval);
            }
        });
    }
    useEffect(() => {
        if (products.length < 1)
            return;

        filter(query);

    }, [products]);

    const fetchMore = async (e: { target: { complete: () => void; }; }) => {

        //	Increment the amount loaded by 6 for the next iteration
        setAmountLoaded(prevAmount => (prevAmount + 6));
        e.target.complete();
    }

    const searchProducts = async () => {

        const searchVal = query;

        if(searchVal === '' && location === '') {
            return;
        }

        filter(searchVal);

        // Update recent searches
        let updatedRecentSearches = [...recentSearches];
        if (!updatedRecentSearches.some(search => search.name === searchVal && search.place === location)) {
            updatedRecentSearches.unshift({ name: searchVal, place: location, min_price: priceMin, max_price: priceMax });
            if (updatedRecentSearches.length > 5) {
                updatedRecentSearches = updatedRecentSearches.slice(0, 5);
            }
            setRecentSearches(updatedRecentSearches);
            await Preferences.set({ key: 'recentSearches', value: JSON.stringify(updatedRecentSearches) });
        }
    }

    const handleSearchFocus = () => {
        setShowRecentSearches(true);
    };

    const handleSearchBlur = () => {
        setTimeout(() => {
            setShowRecentSearches(false)
        }, 200);
    };

    const clickRecent = async (re: RecentSearch) => {

        const searchVal = re.name;

        if(re.place) {
            setLocation(re.place);
        }

        if (searchVal !== "") {
            filter(searchVal);
        }
    };

    return (

        <IonPage id="category-page" className={styles.categoryPage}>
            <IonHeader>
                <IonToolbar>

                    <IonButtons slot="end">
                        <IonBadge color="dark">
                            {shopCart.length}
                        </IonBadge>
                        <IonButton color="dark" routerLink="/cart">
                            <IonIcon ref={cartRef} className="animate__animated" icon={cart} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>

            </IonHeader>

            <IonContent fullscreen>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="dark" routerLink="/" routerDirection="back">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Back
                        </IonButton>
                    </IonButtons>

                    <IonButtons slot="end" class="ion-padding-left">
                        <IonButton color="drukre" routerLink="/cart">
                            {shopCart.length}
                            <IonIcon ref={cartRef} className="animate__animated" icon={cartOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>

                <IonGrid>
                    <IonRow>
                        <IonCol size='9'>
                            <IonRow>
                                <IonCol>
                                    <IonSearchbar className={styles.search}
                                        onIonInput={e => setQuery(e.target.value)} value={query}
                                        placeholder="Search for" searchIcon={searchOutline} animated={true}
                                        onIonFocus={handleSearchFocus}
                                        onIonBlur={handleSearchBlur}
                                    />
                                    {showRecentSearches && recentSearches.length > 0 && <IonCardSubtitle>Recent Searches</IonCardSubtitle>}
                                    {showRecentSearches && recentSearches.map((product, index) => (
                                        <IonButton fill="outline" color="medium" size="default"
                                            style={{ textTransform: 'none' }}
                                            key={index}
                                            onClick={(ev) => clickRecent(product)}>
                                                <IonLabel>
                                                        <IonText>{ product?.name?.length > 10 ? product.name.substring(0, 10)+'..' : product.name}</IonText>
                                                        {product.place && <small> in {product.place.length > 10 ? product.place.substring(0, 10) : product.place}</small>}
                                                        {/*}
                                                    <p>{prevFilterData.min_price ? 'Min $ '+prevFilterData.min_price : null}</p>
                                                */}
                                                </IonLabel>
                                        </IonButton>
                                    ))}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeMd="6" sizeLg="6" sizeSm="12" sizeXs="12" className="ion-padding-horizontal">
                                    <IonInput type="text" value={location} label="Enter Location" labelPlacement="floating" onIonInput={e => setLocation(e.detail.value)}></IonInput>
                                </IonCol>
                                <IonCol sizeMd="6" sizeLg="6" sizeSm="12" sizeXs="12" className="ion-padding-horizontal">
                                    <IonRange
                                        aria-label="Price Range"
                                        labelPlacement="start" label="Price Range"
                                        dualKnobs={true}
                                        value={{
                                            lower: priceMin,
                                            upper: priceMax
                                        }}
                                        min={priceFloor}
                                        max={priceCeiling}
                                        onIonChange={(e) => priceKnobChange(e)}
                                    ></IonRange>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol offsetMd="4" offsetLg="4" sizeMd="3" style={{ textAlign: 'center' }}>
                                    <IonNote> Min: Nu. {priceMin}  - </IonNote>
                                </IonCol>
                                <IonCol sizeMd="3" style={{ textAlign: 'center' }}>
                                    <IonNote>Max: Nu. {priceMax} </IonNote>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size='2' className="ion-align-self-center">
                            <IonButton color="drukre" size="large" onClick={() => clearFilters()}>
                                <IonIcon icon={closeOutline} />&nbsp;
                                <span className="ion-hide-md-down">Clear</span>
                            </IonButton>
                            <IonButton color="drukre" size="large" onClick={() => searchProducts()}>
                                <IonIcon icon={search} />&nbsp;
                                <span className="ion-hide-md-down">Search</span>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonProgressBar color='drukre' buffer={buffer} value={progress}></IonProgressBar>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-text-center">
                        <IonCol size="12">
                            <IonNote>{searchResults && searchResults.length} {(searchResults.length > 1 || searchResults.length === 0) ? " products" : " product"} found</IonNote>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        {searchResults && searchResults.map((product, index) => {

                            if ((index <= amountLoaded) && product.product.images) {
                                return (
                                    <ProductCard key={`search_product_${index}`} product={product.product} category={product.category} index={index} cartRef={cartRef} />
                                );
                            }
                        })}
                    </IonRow>
                </IonGrid>

                <IonInfiniteScroll threshold="100px" onIonInfinite={fetchMore}>
                    <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Fetching more...">
                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
        </IonPage>
    );
}

export default SearchProducts;