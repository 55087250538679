import { Store } from "pullstate";

// Load initial state from localStorage or use default state
const initialState = JSON.parse(localStorage.getItem('drukshop_cartStore')) || {
    total: 0,
    product_ids: []
};

export const CartStore = new Store(initialState);

export const addToCart = (categorySlug, productID) => {
    // Add an object instead of a string
    CartStore.update(s => {
        s.product_ids.push({ categorySlug, productID: parseInt(productID), quantity: 1 });
    });

    localStorage.setItem('drukshop_cartStore', JSON.stringify(CartStore.getRawState()));
}

export const removeFromCart = productIndex => {
    // Remove an item by index
    CartStore.update(s => { s.product_ids.splice(productIndex, 1) });

    localStorage.setItem('drukshop_cartStore', JSON.stringify(CartStore.getRawState()));
}

export const updateCartItem = (productIndex, newDeliveryLocID = null, dzo = null, address = null, price = null, data = [], quantity = null, note = '') => {
    // Update the object at the specified index
    if(address){
        CartStore.update(s => {
            let product = s.product_ids[productIndex];
            product.address = address;
        });
    } else {
        CartStore.update(s => {
            let product = s.product_ids[productIndex];
            let qty = (product.quantity > 1 && quantity == null) ? product.quantity : quantity;
            product.newDeliveryLocID = newDeliveryLocID;
            product.dzo = dzo;
            product.address = address;
            product.price = price;
            product.data = data;
            product.quantity = qty ? qty : 1;
            product.note = note
        });
    }

    localStorage.setItem('drukshop_cartStore', JSON.stringify(CartStore.getRawState()));
}

export const resetCart = () => {
    // Remove drukshop_cartStore from localStorage
    localStorage.removeItem('drukshop_cartStore');
    
    CartStore.update(s => {
        s.total = 0;
        s.product_ids = [];
    }); 
};

// Save state to localStorage before window is closed
window.onbeforeunload = () => {
    //localStorage.setItem('drukshop_cartStore', JSON.stringify(CartStore.getRawState()));
};