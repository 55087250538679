import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, 
	IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonTitle, IonToolbar, useIonModal, IonThumbnail, IonImg, 
	IonSegment, IonSpinner, IonProgressBar, IonSegmentButton, IonLabel, IonFooter, useIonLoading, useIonToast, IonFab, IonFabButton,
	IonInput,
	IonNote
} from "@ionic/react";
import { arrowRedoOutline, cart, cartOutline, chevronBackOutline, heart, 
	heartOutline, add, addCircle, create, refresh,  closeOutline, alertSharp, checkmark, navigateOutline,
	rocketOutline, bagAdd, reloadCircle,
	pencil} from "ionicons/icons";
import { useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHistory } from "react-router-dom";

import { ProductEditor } from '../components/ProductEditor';

import { Sponsor } from '../components/Sponsor';

import { CategoryStore } from '../data/CategoryStore';

import axiosCall from '../data/axios';

import AuthContext from "../UserContext";

import styles from "./Product.module.css";

import { getCaptchaToken } from "../Helper";

import UserStatus from '../components/UserStatus'

const UpdateStock = ({ product, onDismiss, afterEvent }) => {

	const [newStock, setNewStock] = useState(product?.stock);
	const [newPrice, setNewPrice] = useState(product?.actual_price);

	const [spinnerPresent, spinnerDismiss] = useIonLoading();
	const [presentToast] = useIonToast();

	async function saveStock() {

		let dt = new Date();

		if(newStock == '') {
			presentToast({
				message: 'Invalid Stock Value',
				icon: alertSharp,
				color: 'danger',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]
			});
			return;
		}

		if(newPrice == '') {
			presentToast({
				message: 'Invalid Value for Price',
				icon: alertSharp,
				color: 'danger',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]
			});
			return;
		}

		let data = {
			"stock": newStock,
			"price": newPrice,
		};

		/*
		if(price < 1) {
			presentToast({
				message: 'Price cannot be less than Nu. 1',
				icon: alertSharp,
				color: 'danger',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]
			
			});
			return;
		}
		*/

		let formData = new FormData();

		Object.entries(data).map(([key, value]) => {
			formData.append(key, value);
		});

		formData.append('id', product.id);

		var captchaData = {};

        await getCaptchaToken('SAVE').then((token) => { // Here wait token generated
			if(token) {
				formData.append('captchaToken', token);
				formData.append('captchaAction', 'SAVE');
			}
		});

		spinnerPresent({
			message: 'Saving. Please wait...',
			spinner: 'bubbles',
			animated: true,
			cssClass: 'drukre-loading'
		});

		axiosCall("/api/save-product-no-approval", "post", formData, true, 'multipart/form-data')
		.then((res) => {
			//setMyMarkersCheck(false);
			spinnerDismiss();
			presentToast({
				message: 'Product Created Successfully!',
				icon: checkmark,
				color: 'success',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]
			  });

			  afterEvent();
		})
		.catch((error) => {
			//setMessage("Auth failure! Please create an account");
			//setIserror(true);
			spinnerDismiss();
			presentToast({
				message: error.message,
				icon: alertSharp,
				color: 'danger',
				buttons: [
					{
						text: 'Dismiss',
						role: 'cancel'
					}
				]
			  });
		});

	}
	return (
		<IonPage>
			<IonContent>
				<IonGrid>
					<IonRow>
						<IonCol>
							<IonCard>
								<IonCardHeader>
									<IonCardSubtitle>
										<h1>Update Stock/Price</h1>
									</IonCardSubtitle>
								</IonCardHeader>
								<IonCardContent>
									<IonRow>
										<IonCol>
											<IonLabel>Stock</IonLabel>
										</IonCol>
										<IonCol>
											<IonInput label="Your Stock" type="number" labelPlacement="floating" 
											fill="outline" value={newStock} 
											onIonInput={(e) => setNewStock(e.target.value)} />
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonLabel>Your Price</IonLabel>
										</IonCol>
										<IonCol>
											<IonInput label="Nu." type="number" labelPlacement="floating" 
											fill="outline" value={newPrice} 
											onIonInput={(e) => setNewPrice(e.target.value)} />
										</IonCol>
									</IonRow>
								</IonCardContent>
								<IonFooter>
									<IonRow>
										<IonCol size="12" className="ion-text-center">
											<IonNote>The update here will not go through admin validation and will be immediate.</IonNote>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="12" className="ion-text-end">
											<IonButton color="drukre" onClick={() => saveStock()}>
												<IonIcon icon={navigateOutline} />&nbsp;
												Save
											</IonButton>
										</IonCol>
									</IonRow>
								</IonFooter>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};


const MyProducts = () => {

	const history = useHistory();

	const { authValues } = useContext(AuthContext);

	useEffect(() => {
		if (!authValues.authenticated) {
		  history.push('/login');
		}
	}, [authValues]);
	
	const categories = CategoryStore.useState(s => s.categories);
	
	const [products, setProducts] = useState([]);
	
	const [buffer, setBuffer] = useState(0.06);
  
	const [progress, setProgress] = useState(0);
	
	const fetchProducts = async(filter) =>  {

		setProgress(0);
    	setBuffer(0.06);

		// Set progress to 25% when fetching starts
		setProgress(0.25);
        
		await axiosCall("/api/myProducts", 'post', filter, true)
        .then((res) => {

            let newProds = [];

            let inventory = res.data.data;

            inventory.forEach((inv) => {

				let imgObjs = [];

				if(inv.images && inv.images.constructor === Array) {
					inv.images.forEach((img) => {

						imgObjs.push({ 'src': `${process.env.REACT_APP_PRODUCT_ASSETS}/${img}`});
					});
				}

				inv['images'] = imgObjs;

                newProds.push(inv);

            });

            setProducts(newProds);

			// Increment progress by 25% every second after fetching is complete
			const interval = setInterval(() => {
				setProgress((prevProgress) => prevProgress >= 1 ? 1 : prevProgress + 0.25);
				setBuffer((prevBuffer) => prevBuffer >= 1 ? 1 : prevBuffer + 0.25);
			}, 1000);
	
			// Clear interval when progress reaches 100%
			if (progress >= 1) {
				clearInterval(interval);
			}

        })
        .catch((error) => {
            //setIserror(true);

            setProducts([]);
        });
    }
	
	const [filterQuery, setFilterQuery] = useState({status: 'pending'});

    useEffect(() => {

		var q = { };

		if(filterQuery)
			q = Object.assign(q, filterQuery);

		fetchProducts(q);

        if (`${process.env.REACT_APP_ENVIRONMENT}` == 'development') {

        } else {
            //setMarkers(initialMarkers);
            //setProductsCheck(true);
        }
        
    }, [filterQuery]);
	
	//
	
	const [selectedProduct, setSelectedProduct] = useState({});
	
    const editorDismiss = () => {
        setPresentingElement()
    }
	
	const [presentingElement, setPresentingElement] = useIonModal(ProductEditor, {
		product: selectedProduct,
        onDismiss: editorDismiss,
		afterEvent: afterSave
    });
	
	const editProduct = (idx) => {
		if(idx == -1)
			setSelectedProduct({});
		else {
			let theProduct = products[idx];
			setSelectedProduct(theProduct);
		}
		presentingElement({
            initialBreakpoint: 1,
			breakpoints: [0, 0.5, 0.75, 1],
			backdropBreakpoint: 0
        });
	};

	const [presentUpdateStock, setUpdateStock] = useIonModal(UpdateStock, {
		product: selectedProduct,
		afterEvent: afterSave
    });
	const updateStock = (idx) => {
		if(idx == -1)
			return;

		let theProduct = products[idx];
		setSelectedProduct(theProduct);
		
		presentUpdateStock({
            initialBreakpoint: 0.5,
			breakpoints: [0, 0.5, 0.75, 1],
			backdropBreakpoint: 0
        });
	};

	const sponsorDismiss = () => {
        setPresentSponsor()
    }

	const [presentSponsor, setPresentSponsor] = useIonModal(Sponsor, {
		product: selectedProduct,
        onDismiss: sponsorDismiss,
		afterEvent: afterSave
    });

	const sponsoredItem = (idx) => {
		if(idx == -1)
			setSelectedProduct({});
		else {
			let theProduct = products[idx];
			setSelectedProduct(theProduct);
		}
		presentSponsor({
            initialBreakpoint: 1,
			breakpoints: [0, 0.5, 0.75, 1],
			backdropBreakpoint: 0
        });
	};

	function afterSave() {

        setFilterQuery({status: ''});

        setPresentingElement();

		setUpdateStock();

		setFilterQuery({status: filterQuery.status});

    }

	const categoryFilter = (catId) => {
		let cat = categories.filter((f) => catId == f.id);
		return cat;
	};
    return (

        <IonPage id="category-page" className={ styles.categoryPage }>
            <IonHeader>
				<IonToolbar>
                    <IonButtons slot="start">
                        
                    </IonButtons>

                    <IonButtons slot="end">
						<IonButton color="drukre" text="New" onclick = {() => presentingElement()}>
                            <IonIcon color="dark" icon={ chevronBackOutline } />&nbsp; Add New
                        </IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			
			<IonContent fullscreen>
                <IonToolbar>
                    <IonButtons slot="start">
                    </IonButtons>
					{/*
                    <IonButtons slot="end" class="ion-padding-left">
						<IonButton color="drukre" text="New" onclick = {() => editProduct(-1)}>
                            <IonIcon color="drukre" icon={ add } />&nbsp; Add New
                        </IonButton>
						<IonButton color="drukre" text="New" onclick = {() => fetchProducts()}>
                            <IonIcon color="drukre" icon={ refresh } />
                        </IonButton>
					</IonButtons>
					*/}

					<IonSegment onIonChange={e => setFilterQuery({status: e.detail.value})}>              
                        <IonSegmentButton value="published" >
                            <IonLabel>Current Products</IonLabel>
                        </IonSegmentButton>
						<IonSegmentButton value="pending">
                            <IonLabel>Pending Products</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="archived">
                            <IonLabel>Archived Products</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>

				</IonToolbar>

                <IonGrid className="ion-padding">
					<IonRow>
						<IonCol>
							<IonProgressBar color='drukre' buffer={buffer} value={progress}></IonProgressBar>
						</IonCol>
					</IonRow>
                    
					{products.length == 0 ? 
						<IonRow className="ion-text-center ion-margin">
							<IonCol size="12" className="ion-text-center ion-margin">
								No Items Found
							</IonCol>
							<IonCol className="align-items-center ion-margin">
								<IonButton className="ion-margin" size="large" color="drukre" text="New" onclick = {() => editProduct(-1)}>
									<IonIcon icon={ addCircle }  slot="start"></IonIcon>&nbsp; Add New Item
								</IonButton>
							</IonCol>
						</IonRow> 
						: 
						<IonRow class="ion-justify-content-center ion-hide-md-down">
							<IonCol size="4">
								Product Basic
							</IonCol>
							<IonCol size="4">
								Product Description
							</IonCol>
							<IonCol size="2">
								Images
							</IonCol>
							<IonCol size="2">
								
							</IonCol>
						</IonRow>
					}

					{products.map((product, i) => {
							return (
								<IonCard key={i}>
									<IonRow class="ion-justify-content-center">
										<IonCol sizeXs="6" sizeSm="6" sizeMd="4">
											Name: {product.name} <br/>
											Price: { product.actual_price } <br/>
											Stock: {product.stock} <br/>
											Category: {categoryFilter(product.category_id)[0].description} <br/>
											{ product.status == 'pending' ? <IonBadge color="danger"> Pending Admin Validation </IonBadge> :
												<IonBadge color="success" style={{textTransform: 'capitalize'}}>{product.status}</IonBadge> 
											}
										</IonCol>
										<IonCol sizeMd="5" class="ion-hide-md-down">
											{product.description}
										</IonCol>
										<IonCol size="3">
											<IonSegment scrollable={true} key={`Segment-${i}`}>
												{ (product.images && product.images.constructor === Array) ?
													product.images.map((image, ii) => {
													return <IonSegmentButton key={ii}>
															<IonThumbnail key={ii}>
																<img src={ image.src }/>
															</IonThumbnail>
														</IonSegmentButton>
													}
												) : null}
											</IonSegment>
										</IonCol>
									</IonRow>
									<IonRow className="ion-text-center">
										<IonCol>
											<IonButton color="success" onclick={() => updateStock(i)}>
												<IonIcon icon={ pencil } slot="start" />
												Instant Update
											</IonButton>
											<IonButton color="drukre" onclick={() => editProduct(i)}>
												<IonIcon icon={ create } slot="start" />
												Edit Product
											</IonButton>
											<IonButton color="secondary" onclick={() => sponsoredItem(i)}>
												<IonIcon icon={ rocketOutline } slot="start" />
												List as Featured
											</IonButton>
										</IonCol>
									</IonRow>
								</IonCard>
							)}
					)}
                </IonGrid>
				<IonFab horizontal="end" vertical="bottom" slot="fixed">
					<IonFabButton color="primary" onclick = {() => setFilterQuery({status: filterQuery.status})} className="ion-margin-bottom">
						<IonIcon icon={reloadCircle}></IonIcon><sub></sub>
					</IonFabButton>
					<IonFabButton color="drukre" onclick = {() => editProduct(-1)}>
						<IonIcon icon={addCircle}></IonIcon><sub>Add</sub>
					</IonFabButton>
				</IonFab>
            </IonContent>
        </IonPage>
    );
}

export default MyProducts;