import React, { useState, useEffect, useRef } from 'react';
import {
    IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
    IonButtons, IonButton, IonInput, IonItem, IonLabel, IonIcon, IonFooter, styles, IonCardSubtitle,
    IonGrid, IonRow, IonCol, IonItemDivider, IonDatetime, IonCard, IonCardHeader, IonCardTitle, IonCardContent,
    IonProgressBar, IonRadioGroup, IonRadio, IonText, useIonLoading, useIonToast, IonSegment, IonSegmentButton,
    IonNote, useIonModal
}
    from '@ionic/react';
import { chevronBackOutline, checkmarkSharp, eye, eyeOff, wallet, fingerPrint } from 'ionicons/icons';
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";

import axiosCall from '../data/axios';
import { Preferences } from '@capacitor/preferences';

import { Capacitor } from '@capacitor/core';
import { NativeBiometric } from "capacitor-native-biometric";

import AuthContext from "../UserContext";
import { getCaptchaToken } from "../Helper";

import Auth from '../pages/Auth';

import { ProductStore } from "../data/ProductStore";
import { CartStore, removeFromCart, resetCart } from "../data/CartStore";

import PaymentForm from '../components/PaymentGateway';

const GuestForm = ({purchaserData, setPurchaserData}) => {
    const history = useHistory();
    const [phone, setPhone] = useState(purchaserData.userData.phone);

    const phoneVerifyBtnRef = useRef(null);
    
    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const phoneRegex = /^[0-9]{8}$/;

    const [presentToast] = useIonToast();

    useEffect(() => {
        //const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        //setNameError(name ? '' : 'Name is required');
        setPhoneError(phoneRegex.test(phone) ? '' : 'Invalid phone number');
        //setEmailError(emailRegex.test(email) ? '' : 'Invalid email address');

        if (phoneRegex.test(phone)) {
            setPurchaserData({
                isExistingUser: false,
                isUserFormValid: false,
                userData: { phone: phone }
            });
        }
    }, [phone]);

    const SmsOtpModal = ({ dismiss }) => {
        const inputRef = useRef(null);
        let otpConfirm = function() {
            if (inputRef.current?.value) {
                dismiss({ otp: inputRef.current?.value }, 'confirm');
            }else {
                inputRef.current?.setFocus();
            }
        }
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle> Verify Phone</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonItem className="ion-padding">
                        Please enter the OTP code that would have been sent to your mobile number.
                    </IonItem>
                    <IonRow className="ion-padding">
                        <IonCol size="8" offset='2' className="ion-text-center">
                            <IonInput ref={inputRef} labelPlacement="floating" 
                                fill="outline" label="Enter the code here" type="number" />
                        </IonCol>
                    </IonRow>
                    <IonItemDivider/>
                    <IonItem>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton color="medium" onClick={() => dismiss(null, 'cancel')}>
                                    Cancel
                                </IonButton>
                            </IonButtons>
                            <IonButtons slot="end">
                                <IonButton color="drukre" onClick={() => otpConfirm()} strong={true}>
                                    Confirm
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonItem>
                </IonContent>
            </IonPage>
        );
    };

    const [presentOTP, setPresentOTP] = useIonModal(SmsOtpModal, {
        dismiss: (data, role) => setPresentOTP(data, role),
        backdropDismiss: false
    });
    
    const [spinnerPresent, spinnerDismiss] = useIonLoading();

    const processOTP = async () => {

        setPhoneError('');

        if (!phone || !phoneRegex.test(phone)) {
            setPhoneError("Enter a valid phone number");
            return;
        }

        //We could this from the backend if it becomes an issue
        let verifiedPhone = Preferences.get({ key: 'verifiedPhone' });
        // Await the promise resolution
        let verifiedPhoneData = await verifiedPhone;
        if(verifiedPhoneData.value != null){
            if (verifiedPhoneData.value.slice(-8) == phone.slice(-8)) {
                setPurchaserData({
                    isExistingUser: false,
                    isUserFormValid: true,
                    userData: { phone: verifiedPhoneData.value }
                });
                presentToast({
                    message: 'Phone number already verified!',
                    duration: 5000,
                    color: 'success'
                });
                return;
            }
        }

        spinnerPresent({
            message: 'Processing. Please wait...',
            spinner: 'bubbles',
            animated: true,
            cssClass: 'drukre-loading'
        });

        var captchaData = {
            captchaToken: '',
            captchaAction: ''
        };

        await getCaptchaToken('PAYMENT').then((token) => { // Here wait token generated
            if (token) {
                captchaData.captchaToken = token;
                captchaData.captchaAction = 'PAYMENT';
            }
        });

        //Fire backend rma api call
        axiosCall("/api/send-sms-verification", 'post', 
            { to: phone, captchaData: captchaData }, true)
        .then((res) => {
            if (res.data.status !== 1){
                setPhoneError('Phone verification unsuccessful! ');
            }
            else {
                phoneVerifyBtnRef.current.disable = true;
                presentOTP({
                    initialBreakpoint: 0.5,
                    breakpoints: [0.35, 0.5, 0.7, 1],
                    backdropBreakpoint: 0,
                    backdropDismiss: false,
                    onWillDismiss: (ev) => {
                        if (ev.detail.role === 'confirm') {
                            (ev.detail).to = phone;
                            afterOTP(ev.detail);
                        }
                    }
                });
                setTimeout(function(){
                    phoneVerifyBtnRef.current.disable = false;
                }, 10000);
            }
        }).catch((error) => {
            setPhoneError("Phone verification unsuccessful! "+ error.message);
            //setIserror(true);
        }).finally(() => {
            spinnerDismiss();
        });
    };

    function afterOTP(data) {

        spinnerPresent({
            message: 'Verifying Phone. Please wait...',
            spinner: 'bubbles',
            animated: true,
            cssClass: 'drukre-loading'
        });

        axiosCall("/api/verify-sms", 'post', data, true)
        .then((res) => {
            if (res.data.status !== 1){
                setPhoneError('Phone verification unsuccessful! ' + res.data.message);
            }
            else {
                setPurchaserData({
                    isExistingUser: false,
                    isUserFormValid: true,
                    userData: { phone: phone }
                });
                Preferences.set({
					key: 'verifiedPhone',
					value: phone,
				});
            }
        }
        ).catch((error) => {
            setPhoneError('Phone verification unsuccessful! ' + error.message);
        }).finally(() => {
            spinnerDismiss();
        });
    }

    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonInput label="Mobile Number" labelPlacement="floating" fill="outline" type="number" 
                        onIonChange={(e) => setPhone(e.detail.value)} required value={purchaserData.userData.phone}>
                    </IonInput>
                    {phoneError && <p style={{ fontSize: "small", color:"red", textAlign: "center"  }}>{phoneError}</p>}
                    <IonButton className="ion-padding ion-float-right" color="drukre" onClick={processOTP} ref={phoneVerifyBtnRef}
                        disabled={purchaserData.isUserFormValid}>
                        <IonIcon icon={checkmarkSharp} />&nbsp; Verify Phone
                    </IonButton>
                 </IonCol>
            </IonRow>
            <p style={{ fontSize: "small", textAlign: "center"  }}>
                    By continueing you agree to our <span onClick={() => history.push("/terms-and-conditions")}>Terms and Conditions</span>
            </p>
            
            {error && <p>{error}</p>}

        </IonGrid>
    );
};

const LoginForm = ({ setIsAuthenticated, setPurchaserData }) => {

    const history = useHistory();

    useEffect(() => {
        setPurchaserData({
          isExistingUser: false,
          isUserFormValid: false,
          userData: {}
        });
    }, [setPurchaserData]);
    
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState();
    const [iserror, setIserror] = useState(false);
    const [message, setMessage] = useState("");
    const [mode, setMode] = useState('login');

    const { updateUser, authValues } = React.useContext(AuthContext);
    
    const [bmEmail, setBmEmail] = useState();
	const [triggerLogin, setTriggerLogin] = useState(false);

    if (Capacitor.isNativePlatform()) {
		Preferences.get({ key: 'userEmail' }).then((emailData) => {
			if (emailData.value != null)
				setBmEmail(emailData.value);
		});
	}

    const handleBiometricAuth = async () => {
		try {
			console.log('BiometricAuth.isAvailable called');
			const bmIsAvailable = await NativeBiometric.isAvailable();
			if(!bmIsAvailable.isAvailable){
				console.log('BiometricAuth.isAvailable not available');
				setMessage('Biometric authentication not available on your device');
				setIserror(true);
				return
			}
			//const isFaceID = bmIsAvailable.biometryType == BiometryType.FACE_ID;
			console.log('BiometricAuth.verify called');
			const result = await NativeBiometric.verifyIdentity({
				reason: "For easy log in",
				title: "Log In",
				subtitle: "Biometric Authentication",
				description: "Facial or Fingerprint Authentication",
			})
			.then(() => true)
			.catch(() => false);

			if (result) {
				console.log('BiometricAuth.verify result:', result);
				if (bmEmail != null)
					setEmail(bmEmail);
				let userPassword = Preferences.get({ key: 'userPassword' });
				// Await the promise resolution
				let userPasswordData = await userPassword;
				if (userPasswordData.value != null)
					setPassword(userPasswordData.value);
				// Trigger login after setting email and password
                setTriggerLogin(true);
			} else {
				console.log('BiometricAuth.verify failed');
				setMessage('Authentication failed!');
				setIserror(true);
			}
		} catch (error) {
			setMessage('Error during authentication: ' + error.message);
			setIserror(true);
		}
	};

	useEffect(() => {
        if (triggerLogin && email && password) {
            handleLogin();
            setTriggerLogin(false); // Reset the trigger
        }
    }, [triggerLogin]);

    const handleLogin = async () => {
        if (!email) {
            setMessage("Please enter a valid email");
            setIserror(true);
            return;
        }

        if (!password || password.length < 6) {
            setMessage("Please enter your password");
            setIserror(true);
            return;
        }

        const loginData = {
            "email": email,
            "password": password,
            "captchaToken": null,
            "captchaAction": ''
        }

        await getCaptchaToken('LOGIN').then((token) => { // Here wait token generated
            if (token) {
                loginData.captchaToken = token;
                loginData.captchaAction = 'LOGIN';
            }
        });

        axiosCall("/api/login", 'post', loginData)
            .then(async (res) => {
                Preferences.set({
                    key: 'accessToken',
                    value: res.data.access_token,
                });
                await updateUser({
                    ...authValues,
                    isLoading: true
                });
                setPurchaserData({
                    isExistingUser: true,
                    isUserFormValid: true
                });
                if (Capacitor.isNativePlatform()) {
					Preferences.set({
						key: 'userEmail',
						value: email
					});
					Preferences.set({
						key: 'userPassword',
						value: password
					});
				}
            })
            .catch((error) => {
                setMessage("CheckOut- Auth failure! " + error.message);
                setIserror(true);
            });

    };

    const handleRegistration = async () => {
        if (!email) {
            setMessage("Please enter a valid email");
            setIserror(true);
            return;
        }
        if (!phone) {
            setMessage("Please enter a valid phone");
            setIserror(true);
            return;
        }

        const regData = {
            "name": name,
            "email": email,
            "phone": phone,
            "captchaToken": null,
            "captchaAction": ''
        }

        await getCaptchaToken('SIGNUP').then((token) => { // Here wait token generated
            if (token) {
                regData.captchaToken = token;
                regData.captchaAction = 'SIGNUP';
            }
        });

        const headers = {
            'Content-Type': 'application/json'
        }

        axiosCall("/api/register", 'post', regData, true)
            .then((res) => {
                setIsAuthenticated(true);
                setPurchaserData({
                    isExistingUser: true,
                    isUserFormValid: true,
                    userData: regData
                });
            })
            .catch((error) => {
                setMessage("Unable to create account. " + error.message);
                setIserror(true);
            });

    };

    const handlePasswordReset = () => {

        if (!email) {
            setMessage("Please enter a valid email");
            setIserror(true);
            return;
        }
        const resetData = {
            "email": email
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        axiosCall("/api/resetPassword", 'post', resetData, true)
            .then((res) => {
                setMessage("Password Reset Email sent. Please check your email.");
            })
            .catch((error) => {
                setMessage("Unable to reset password. " + error.message);
                setIserror(true);
            });
    };

    const [showPassword, setShowPassword] = useState(false);

    return (
        <IonGrid>
            <IonRow>
                {mode == '/register' ?
                    <IonCol>
                        <IonRow>
                            <IonCol>
                                <IonLabel color="danger">{message}</IonLabel>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel position="stacked"> Name </IonLabel>
                                    <IonInput type="text" onIonChange={(e) => setName(e.detail.value)}>
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel position="stacked"> Mobile Number</IonLabel>
                                    <IonInput type="number" onIonChange={(e) => setPhone(e.detail.value)}>
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel position="stacked"> Email</IonLabel>
                                    <IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value)} >
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <p style={{ fontSize: "small" }}>
                                    By clicking REGISTER you agree to our <span onClick={() => history.push("/terms-and-conditions")}>Terms and Conditions</span>
                                </p>
                                <IonButton color="drukre" onClick={handleRegistration}>Register</IonButton>
                                <p style={{ fontSize: "medium" }}>
                                    Already have an account? <a onClick={() => setMode('/login')}>Sign In!</a>
                                </p>

                            </IonCol>
                        </IonRow>
                    </IonCol>
                    : mode == '/resetpassword' ?
                        <IonCol >
                            <IonRow>
                                <IonCol>
                                    <IonLabel color="danger">{message}</IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel position="stacked"> Email</IonLabel>
                                        <IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value)}>
                                        </IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton color="drukre" onClick={handlePasswordReset}>Reset Password</IonButton>
                                    <p style={{ fontSize: "medium" }}>
                                        Already have an account? <a onClick={() => setMode('/login')}>Sign In!</a>
                                    </p>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        :
                        <IonCol>
                            <IonRow>
                                <IonCol>
                                    <IonLabel color="danger">{message}</IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel position="stacked"> Email</IonLabel>
                                        <IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value)}>
                                        </IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel position="stacked"> Password</IonLabel>
                                        <IonInput 
                                            type={showPassword ? "text" : "password"} 
                                            value={password} 
                                            onIonChange={(e) => setPassword(e.detail.value)} 
                                        />
                                        <IonIcon 
                                            slot="end" 
                                            icon={showPassword ? eyeOff : eye} 
                                            onClick={() => setShowPassword(!showPassword)}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <p style={{ fontSize: "small" }}>
                                        By clicking LOGIN you agree to our <span onClick={() => history.push("/terms-and-conditions")}>Terms and Conditions</span>
                                    </p>
                                    <IonButton color="drukre" onClick={handleLogin}>Login</IonButton>
                                    <p style={{ fontSize: "medium" }}>
                                        Don't have an account? <a onClick={() => setMode('/register')}>Sign up!</a> |
                                        Forgot Password? <a onClick={() => setMode('/resetpassword')}>Reset!</a>
                                    </p>
                                    {Capacitor.isNativePlatform() && bmEmail != null &&
                                        <IonButton color="drukre" onClick={handleBiometricAuth}><IonIcon icon={fingerPrint}></IonIcon>Facial/Fingerprint Login</IonButton>
                                    }
                                </IonCol>
                            </IonRow>
                        </IonCol>
                }
            </IonRow>
        </IonGrid>
    );
};

export const UserDetailsForm = ({purchaserData, setPurchaserData}) => {

    const { authValues, fetchUser, logout } = React.useContext(AuthContext);
    const [isAuthenticated, setIsAuthenticated] = useState(authValues.authenticated);

    useEffect(() => {
        if(isAuthenticated)
            setPurchaserData(
                {
                    isExistingUser: true,
                    isUserFormValid: true,
                    userData: authValues.user.data
                }
            );
    }, [isAuthenticated, setPurchaserData, authValues.user.data]);

    const [selectedValue, setSelectedValue] = useState(purchaserData.isExistingUser ? 'login' : 'guest');

    const handleSelectionChange = (e) => {
        setSelectedValue(e.detail.value);
    };

    return (
        <>
            {!isAuthenticated ? (
                <>
                    <IonRadioGroup value={selectedValue} onIonChange={handleSelectionChange}>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonItem lines="none">
                                        <IonLabel>Log In First</IonLabel>
                                        <IonRadio slot="start" value="login" />
                                    </IonItem>
                                </IonCol>
                                <IonCol>
                                    <IonItem lines="none">
                                        <IonLabel>Or Check Out as Guest</IonLabel>
                                        <IonRadio slot="start" value="guest" />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonRadioGroup>
                    {selectedValue == 'guest' ? (
                        <GuestForm purchaserData={purchaserData} setPurchaserData={setPurchaserData} />
                    ) : (
                        <LoginForm setIsAuthenticated={setIsAuthenticated} setPurchaserData={setPurchaserData}/>
                    )}
                </>
            ) :
                <>
                    <p style={{ fontSize: "small", textAlign: "center" }}>
                        Processing the order on the user account session.
                    </p>
                    <IonText>
                        <p style={{ fontSize: "small", textAlign: "center" }}>
                            You are logged in as <IonNote>{authValues.user.data.email}</IonNote> 
                            {authValues.user.data.phone && 
                                <span> with phone <IonNote>{authValues.user.data.phone} </IonNote></span> }
                        </p>
                        <p style={{textAlign: "center"}}>
                            Please cross check your details before proceeding.
                        </p>
                    </IonText>
                </>
            }
        </>
    )
};

const Checkout = () => {
    const [step, setStep] = useState(1);

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const getProgressValue = () => {
        return step === 1 ? 0.5 : 1;
    };

    const history = useHistory();

    const [ purchaserData, setPurchaserData ] = useState({
        isExistingUser: false,
        isUserFormValid: false,
        userData: {}
    });

    const [total, setTotal] = useState(0);

    const products = ProductStore.useState(s => s.products);
    const productsFullyLoaded = ProductStore.useState(s => s.fullyLoaded);
    const shopCart = CartStore.useState(s => s.product_ids);

    useEffect(() => {

        const getCartProducts = () => {

            setTotal(0);

            shopCart.forEach(product => {
            
                var cartParts = product;
                var categorySlug = cartParts.categorySlug;
                var productID = cartParts.productID;

                var deliveryLocID = cartParts.newDeliveryLocID ? parseInt(cartParts.newDeliveryLocID) : null;
                var dzongkhag = cartParts.dzo;
                var address = cartParts.address;
                var delPrice = cartParts.price;
                var quantity = cartParts.quantity;

                const tempCategory = products.filter(p => p.slug === categorySlug)[0];
                const tempProduct = tempCategory.products.filter(p => parseInt(p.id) === parseInt(productID))[0];

                setTotal(prevTotal => prevTotal + parseInt(tempProduct.price * quantity));
                delPrice && setTotal(prevTotal => prevTotal + parseInt(delPrice));
            });
        }

        if(productsFullyLoaded)
            getCartProducts();
    }, [productsFullyLoaded, shopCart]);

    const onCompletion = () => {
        resetCart();
        history.push('/cart');
    }
    
    if(shopCart.length == 0)
    {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Checkout</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>Cart is Empty</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <p style={{ fontSize: "medium" }} className="ion-text-center">
                                Please add some items to the cart before proceeding to checkout.
                            </p>
                        </IonCardContent>
                    </IonCard>
                </IonContent>
            </IonPage>
        );
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="dark" text="Back to Cart" routerLink="/cart" routerDirection="back">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Back
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Checkout</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="dark" text="Back to Cart" routerLink="/cart" routerDirection="back">
                            <IonIcon color="dark" icon={chevronBackOutline} />&nbsp;Back
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Order Confirmation</IonTitle>
                </IonToolbar>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-align-self-center" size-md="6" offset-md="3">
                            <br></br>
                            <IonProgressBar color="drukre" value={getProgressValue()}></IonProgressBar>
                            {step === 1 && <UserDetailsForm purchaserData={purchaserData}
                                setPurchaserData={setPurchaserData} onNext={handleNextStep} />}
                            {step === 2 && <PaymentForm purchaserData={purchaserData} payLoad={shopCart} 
                                api= '/api/orders' total={total} onSuccess={onCompletion} onPrevious={handlePreviousStep} />}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size-md="6" offset-md="3">
                            
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter>
                {/*
                <IonButton color="drukre"  disabled={!isPaid}>
                    <IonIcon icon={checkmarkSharp}/>&nbsp;Confirm Order
                </IonButton>
                */}
                <div className="step-navigation">
                    {step > 1 && (
                        <IonButton color="medium" className="ion-padding" onClick={handlePreviousStep}>
                            Back
                        </IonButton>
                    )}
                    {step < 2 && (
                        <IonButton color="drukre" disabled={!purchaserData.isUserFormValid} className="ion-float-right ion-padding" onClick={handleNextStep}>
                            Go to Payment
                        </IonButton>
                    )}
                </div>
            </IonFooter>
        </IonPage>
    );
};

export default Checkout;



// STRIPE src/pages/Checkout.js

/*
import React, { useState } from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonInput, IonItem, IonLabel } from '@ionic/react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('your-publishable-key-from-stripe');

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
        email,
      },
    });

    if (error) {
      setError(error.message);
      return;
    }

    // Send paymentMethod.id to your server to create a payment intent
    // For example:
    // const response = await fetch('/create-payment-intent', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ payment_method: paymentMethod.id }),
    // });
    // const paymentIntent = await response.json();

    setSuccess(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <IonItem>
        <IonLabel position="stacked">Name</IonLabel>
        <IonInput value={name} onIonChange={(e) => setName(e.detail.value)} required />
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Email</IonLabel>
        <IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value)} required />
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Card Details</IonLabel>
        <CardElement />
      </IonItem>
      {error && <p>{error}</p>}
      {success ? (
        <p>Payment Successful!</p>
      ) : (
        <IonButton type="submit" disabled={!stripe}>
          Pay
        </IonButton>
      )}
    </form>
  );
};

const Checkout = () => (
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>Checkout</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </IonContent>
  </IonPage>
);

export default Checkout;
*/
